import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Navigator from "../Components/Navigator";

import counterpart from 'counterpart';
import Container from "../Components/Container";

/**
 * @author Jakob Froembgen
 * description: screen description
 */
class Messages extends Component {

  render() {

    const { classes } = this.props

    const testData = [
      ["Jakob", "last messsage messsage messsage messsage messsage messsage"],
      ["Max", "last messsage"],
      ["Ulrich", "last messsage"],
      ["David", "last messsage"],
      ["Lukas", "last messsage"]
    ]

    return (
      <>
        <Grid container spacing={1} direction="column" justify="center" >
          {testData.map(testData => (
            <Link to="/chat">
              <Container
                key={testData[0]}
                text={testData[0]}
                lastMessage={testData[1]}
              />
            </Link>
          ))}
        </Grid>
        <Navigator title={"Nachrichten"} />
      </>
    );
  }
}

// define classes and style your components
let useStyles = theme => ({
  //style
})

export default withStyles(useStyles)(Messages);