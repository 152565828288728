import React, { Component } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import counterpart from 'counterpart'
import { Visibility, VisibilityOff } from '@material-ui/icons'

/**
 * simple password input
 * speacial feature: icon that toggles hiding/showing the password ('password' vs. '********')
 */
class PasswordInput extends Component {

  state = { showPassword: false }

  render() {
    return (
      <TextField
        inputProps={{ 'aria-label': 'password' }}
        type={this.state.showPassword ? "text" : "password"}
        label={counterpart.translate('loginScreen.password')}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => this.setState({ showPassword: !this.state.showPassword })}
            >
              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }}
        {...this.props}
      />);
  }
}

export default PasswordInput;