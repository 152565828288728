import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import queryString from 'query-string';

import { Grid, Typography, Button, Box } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import PostData from '../services/PostData'

import counterpart from 'counterpart';

let navIds = {
  "": 0,
  "chat": 1,
  "settings": 3
}

/**
 * @author Max Mustermann
 * description: component description
 */
class Navigator extends Component {

  state = {
    navIndex: null
  }

  componentDidMount() {
    let site = window.location.hash.replace("#", "").replace("/", "");
    if (site in navIds)
      this.setState({ navIndex: navIds[site] })
  }

  render() {

    const { classes } = this.props

    return (
      <>
        <div className={classes.header}>
          <Link to="/">
            <Button className={classes.backButton}>
              <div className={classes.triangle} />
              {/* {this.props.btnText} */}
              Home
            </Button>
          </Link>
          <Typography variant="h4" className={classes.headline}>
            {this.props.title}
          </Typography>
        </div>
        <div className={classes.navigatorWrapper}>
          <BottomNavigation
            value={this.state.navIndex}
            onChange={(e, v) => { this.setState({ navIndex: v }); console.log(v) }}
            showLabels={false}
            className={classes.navigator}
          >
            <BottomNavigationAction
              className={classes.button}
              label={counterpart.translate("navigator.homeIcon")}
              icon={<HomeIcon />}
              onClick={() => this.props.history.push("/")}
            />
            <BottomNavigationAction
              className={classes.button}
              label={counterpart.translate("navigator.chatBubbleIcon")}
              icon={<ChatBubbleIcon />}
              onClick={() => this.props.history.push("/chat")}

            />
            <BottomNavigationAction
              className={classes.button}
              label={counterpart.translate("navigator.menuIcon")}
              icon={<MenuIcon />}
            // onClick={() => this.props.history.push("/")}
            />
            <BottomNavigationAction
              className={classes.button}
              label={counterpart.translate("navigator.settingsIcon")}
              icon={<SettingsIcon />}
              onClick={() => this.props.history.push("/settings")}

            />
          </BottomNavigation>
        </div>
      </>
    );
  }
}

let useStyles = theme => ({
  header: {
    boxSizing: "border-box",
    padding: "10px 30px",
    left: "0px",
    width: "100%",
    position: "fixed",
    display: "flex",
    top: "0px"
  },
  headline: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)"
  },
  backButton: {
    background: "#293462",
    color: "white",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    "&:hover": {
      backgroundColor: "#293462"
    }
  },
  triangle: {
    position: "absolute",
    right: "100%",
    borderTop: "18px solid transparent",
    borderRight: "18px solid #293462",
    borderBottom: "18px solid transparent"
  },
  navigatorWrapper: {
    color: "white !important",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%"
  },
  navigator: {
    backgroundColor: "#293462"
  },
  button: {
    color: "white !important"
  }
})

export default withStyles(useStyles)(withRouter(Navigator));