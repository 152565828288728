import counterpart from 'counterpart';
import en from './../lang/en';
import de from './../lang/de';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('de', de);

/**
 * ?
 * @param {*} type 
 * @param {*} userData 
 */
export default function PostData(type, userData = {}) {
  let development = process.env.NODE_ENV === "development"
  let baseUrl = window.location.protocol + "//" + window.location.hostname + (development ? ":8000" : "") + "/api/";

  /**
   * ?
   */
  return new Promise((resolve, reject) => {

    let formData = new FormData();
    for (let [key, value] of Object.entries(userData)) {
      console.log(key, value)
      formData.append(key, value)
    }
    fetch(baseUrl + type + ".php", {
      method: "POST",
      body: formData,
      credentials: "include",
      headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        resolve(responseJson)
        if ("error" in responseJson) {
          responseJson.error_code = responseJson.error;
          responseJson.error = counterpart.translate('error.' + responseJson.error)
        }
      })

      // ?
      .catch(error => {
        reject(counterpart.translate("error.ERR_SERVER"))
      })
  })
}