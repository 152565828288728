import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

/**
 * @author Jakob Froembgen
 * description: Message container Chat Screen
 */
class Message extends Component {

  render() {

    const { classes } = this.props

    return (
      <>
        <div className={`
          ${classes.messageContainer}
          ${this.props.orientation === "left" ? classes.left : classes.right}
        `}>
          <div className={`
            ${classes.triangle}
            ${this.props.orientation === "left" ? classes.leftTriangle : classes.rightTriangle}
          `} />
          <Typography>{this.props.text}</Typography>
          <div className={classes.dateText}>{this.props.date}</div>
        </div>
      </>
    );
  }
}

let useStyles = theme => ({
  messageContainer: {
    position: "relative",
    width: "80%",
    boxSizing: "border-box",
    wordBreak: "break-all",
    textAlign: "left",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "10px",
    marginBottom: "20px",
    flexFlow: "wrap",
    justifyContent: "space-between",
    alignContent: "space-between"
  },
  right: {
    borderTopRightRadius: 0,
    backgroundColor: "lightgrey",
    marginLeft: "20%"
  },
  left: {
    borderTopLeftRadius: 0,
    backgroundColor: "white",
    marginRight: "20%"
  },
  triangle: {
    top: 0,
    position: "absolute",
    right: "100%",
    borderRight: "12px solid transparent",
    borderBottom: "12px solid transparent"
  },
  rightTriangle: {
    borderRight: "0",
    borderLeft: "12px solid lightgrey",
    left: "100%"
  },
  leftTriangle: {
    borderRight: "12px solid white",
    right: "100%"
  },

  dateText: {
    fontStyle: "italic",
    textAlign: "right",
    fontSize: "80%"
  },
})

export default withStyles(useStyles)(Message);