// default
import React, { Component } from 'react';
import './App.css';

// screens
import About from './Screens/About';
import Register from './Screens/Register';
import Login from './Screens/Login';
import Feed from './Screens/Feed';
import Filter from './Screens/Filter';
import Messages from './Screens/Messages';
import Chat from './Screens/Chat';

// routes
import ProtectedRoute from './services/ProtectedRoute';
import UnProtectedRoute from './services/UnProtectedRoute';

// router
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

// MaterialUI
import { withStyles } from '@material-ui/core/styles';

// language translations
import counterpart from 'counterpart';
import en from './lang/en';
counterpart.registerTranslations('en', en);

class App extends Component {
  render() {

    const { classes } = this.props

    return (
      <div className={classes.app}>
        <div className={classes.container}>
          <Router>
            <Switch>

              {/* TEMPLATE: url "/" leads to "Home" screen */}
              <ProtectedRoute path="/" exact component={Feed} />
              <ProtectedRoute path="/filter" exact component={Filter} />
              <ProtectedRoute path="/messages" exact component={Messages} />
              <ProtectedRoute path="/chat" exact component={Chat} />

              <UnProtectedRoute path="/login" exact component={Login} />
              <UnProtectedRoute path="/register" exact component={Register} />
              
              <Route path="/about" exact component={About} />
            </Switch>
          </Router>
        </div>
      </div>
    );
  }
}

let useStyles = theme => ({
  app: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    minHeight: "100%",
    padding: "10px",
    boxSizing: "border-box"
  },
  container: {
    padding: "60px 0",
    position: "inherit",
    textAlign: "center",
    left: "auto",
    top: "auto",
    width: "100%",
    maxWidth: "350px",
    transform: "none",
    color: "#000000"
  }
});

export default withStyles(useStyles)(App);