import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthData from './AuthData';
import PostData from './PostData';
import counterpart from 'counterpart';

/**
 * ?
 */
class ProtectedRoute extends Route {

  state = {
    redirect: null,
    authenticated: false,
  }

  componentDidMount() {
    this._updateData()
  }

  /**
   * ?
   */
  _updateData() {
    this.setState({ authenticated: false })

    // ?
    PostData("logged_in")
      .then(result => {

        // ?
        if (result.type) {
          AuthData.email = result.email
          AuthData.name = result.name
          AuthData.language = result.language
          AuthData.authenticated = true;
          counterpart.setFallbackLocale("en");
          counterpart.setLocale(result.language)
          this.setState({ authenticated: true })
        } else {
          this.setState({ redirect: "/login" })
        }
      })
      .catch(error => {
        this.setState({ redirect: "/login" })
      })
  }

  render() {
    if (this.state.redirect)
      return <Redirect to={{ pathname: this.state.redirect, search: `?next=${encodeURIComponent(this.props.location.pathname)}` }} />
    if (!this.state.authenticated)
      return null
    if (AuthData.language !== counterpart.getLocale())
      counterpart.setLocale(AuthData.language)
    return (
      <Route
        {...this.props.rest}
        render={props => {
          return (
            <this.props.component user={AuthData} {...props} {...this.props} updateData={() => this._updateData()} />
          );
        }}
      >

      </Route>
    )
  }
}
export default ProtectedRoute