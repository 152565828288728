import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

/**
 * @author Jakob Froembgen
 * description: Container Filter Screen
 */
class Container extends Component {

	render() {

		const { classes } = this.props

    // cut off last message if length > 30
    let lastMessage = this.props.lastMessage;
    if (lastMessage) {
      if (lastMessage.length > 30);
        lastMessage = lastMessage.slice(0, 30) + "..."
    }

		return (
			<>
				<div
          className={classes.container}
          onClick={this.props.onClick}
        >
          <div>
          {this.props.lastMessage?
            <>
              <AccountCircleIcon style={{ fontSize: "38px", marginRight: "10px" }} />
              <Typography style={{ display: "inline-block" }}>
                {this.props.text}
                <Typography className={classes.lastMessage}>
                  <i>{this.props.lastMessage}</i>
                </Typography>
              </Typography>
            </>
            : 
            <Typography>{this.props.text}</Typography>
            }
          </div>
          <div>{">"}</div>
        </div>
			</>
		);
	}
}

let useStyles = theme => ({
	container: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "15px 25px",
    marginBottom: "20px",
    textAlign: "left",
    fontWeight: "400",
    cursor: "pointer",

    display: "flex",
    flexFlow: "wrap",
    justifyContent: "space-between",
    alignContent: "space-between",
    
    WebkitBoxShadow: "0px 5px 10px -6px rgba(0,0,0,0.4)",
    MozBoxShadow: "0px 5px 10px -6px rgba(0,0,0,0.4)",
    boxShadow: "0px 5px 10px -6px rgba(0,0,0,0.4)"
  },
  lastMessage: {
    fontSize: "14px"
  }
})

export default withStyles(useStyles)(Container);