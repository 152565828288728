import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Navigator from "../Components/Navigator"

import counterpart from 'counterpart';

/**
 * @author Max Mustermann
 * description: About Screen - a simple code snippet to demonstrate how to create a screen
 */
class About extends Component {

  render() {

    const { classes } = this.props

    return (
      <>
        <Grid container spacing={1} direction="column" justify="center" >
          <Typography variant="h3">
            {counterpart.translate("abobutScreen.title")}
          </Typography>
          <Typography style={{ padding: "42px 0" }}>
            {counterpart.translate("abobutScreen.text")}
            <code>{counterpart.translate("abobutScreen.code")}</code>
          </Typography>
          <Link to="/">
            <Button variant="contained" color="primary" fullWidth>
              {counterpart.translate("abobutScreen.btn.takeMeHome")}
            </Button>
          </Link>
          <Navigator />
        </Grid>
      </>
    );
  }
}

// define classes and style your components
let useStyles = theme => ({
  //style
})

export default withStyles(useStyles)(About);