import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Navigator from "../Components/Navigator"

import counterpart from 'counterpart';
import Container from "../Components/Container";

/**
 * @author Jakob Froembgen
 * description: Filter Screen
 */
class Filter extends Component {

	text = {
		"filter": [
			[
				"Klassenstufe", [
					"5. Klasse",
					"6. Klasse",
					"7. Klasse",
					"8. Klasse",
					"9. Klasse",
					"10. Klasse",
					"11. Klasse",
					"12. Klasse"
				]
			],
			[
				"Fächer", [
					"Biologie",
					"Chemie",
					"Deutsch",
					"Englisch",
					"Französisch",
					"Geschichte",
					"Griechisch",
					"Kunst",
					"Latein",
					"Mathe",
					"Musik",
					"Physik"
				]
			],
			[
				"Wochentage", [
					"Montag",
					"Dienstag",
					"Mittwoch",
					"Donnerstag",
					"Freitag",
					"Samstag",
					"Sonntag"
				]
			],
			[
				"Ort", [
					"Online",
					"Schule"
				]
			],
			[
				"Zahlung", [
					"Kostenlos",
					"5-10€",
					"11-17€",
					"18-22€",
				]
			]
		],
		"filter": [
			[
				"Klassenstufe", [
					"5. Klasse",
					"6. Klasse",
					"7. Klasse",
					"8. Klasse",
					"9. Klasse",
					"10. Klasse",
					"11. Klasse",
					"12. Klasse"
				]
			],
			[
				"Fächer", [
					"Biologie",
					"Chemie",
					"Deutsch",
					"Englisch",
					"Französisch",
					"Geschichte",
					"Griechisch",
					"Kunst",
					"Latein",
					"Mathe",
					"Musik",
					"Physik"
				]
			],
			[
				"Wochentage", [
					"Montag",
					"Dienstag",
					"Mittwoch",
					"Donnerstag",
					"Freitag",
					"Samstag",
					"Sonntag"
				]
			],
			[
				"Ort", [
					"Online",
					"Schule"
				]
			],
			[
				"Zahlung", [
					"Kostenlos",
					"5-10€",
					"11-17€",
					"18-22€",
				]
			]
		]
	}

	state = {
		container: this.text
	}

	render() {

		return (
			<>
				<Grid container spacing={1} direction="column" justify="center" >
					{Object.keys(this.state.container).map((key, index) =>
						<Container
							key={key}
							text={this.state.container[key][0]}
							onClick={(e) => {
								this.setState({ container: this.state.container[key] });
							}}
						/>
					)}
				</Grid>
				<Navigator
					title={counterpart.translate("filterScreen.title")}
				/>
			</>
		);
	}
}

// define classes and style your components
let useStyles = theme => ({

})

export default withStyles(useStyles)(Filter);