import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#293462",
		},
		secondary: {
			main: "#293462",
		},
	},
});

export default theme;