import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthData from './AuthData';
import PostData from './PostData';
import counterpart from 'counterpart';

class UnProtectedRoute extends Route {

  state = {
    redirect: null,
    authenticated: true
  }

  componentDidMount() {
    const userLang = navigator.language.substring(0, 2)
    counterpart.setFallbackLocale("en")
    counterpart.setLocale(userLang)

    /**
     * ?
     */
    PostData("logged_in")
      .then(result => {
        if (result.type) {
          AuthData.email = result.email
          AuthData.name = result.name
          this.setState({ redirect: "/profile" })
        } else {
          this.setState({ authenticated: false })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    if (this.state.redirect)
      return <Redirect to={this.state.redirect} />
    if (this.state.authenticated)
      return null
    return (
      <Route
        {...this.props.rest}
        render={props => {
          return (
            <this.props.component {...props} />
          );
        }}
      >

      </Route>
    )
  }
}
export default UnProtectedRoute