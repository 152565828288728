import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField } from '@material-ui/core';
import Navigator from "../Components/Navigator";

import counterpart from 'counterpart';
import Message from "../Components/Message";

/**
 * @author Jakob Froembgen
 * description: chat window
 */
class Chat extends Component {

  state = {
    messages: [
      [
        "hallo",
      ],
      [
        "Hdf...",
        "Lorem Ipsum is simply a type specimen book. into electronic typesetting, remaining essentially unchanged. It was popularised and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ],
    ]
  }

  render() {

    const { classes } = this.props

    return (
      <>
        <Grid container spacing={1} direction="column" justify="center" style={{ paddingBottom: "60px" }}>
          {this.state.messages[0].map(msg => (
            <Message orientation="left" key={msg} text={msg} date={"01.01.2001"} />
          ))}
          {this.state.messages[1].map(msg => (
            <Message orientation="right" key={msg} text={msg} date={"01.01.2001"} />
          ))}

          {/** user input */}
          <div className={classes.userInputContainer}>
            <TextField
              className={classes.userInput}
              placeholder={counterpart.translate("chatScreen.inputPlaceholder")}
              id="message-input"
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              className={classes.sendButton}
              onClick={() => {
                let messageInput = document.getElementById("message-input");

                // check whether input is empty
                if (messageInput.value.replace(/\s/g, "") === "")
                  return

                this.setState(state => {
                  const messages = state.messages;
                  messages[1].splice(messages[1].length, 0, messageInput.value);
                  messageInput.value = "";

                  setTimeout(() => {
                    document.documentElement.scrollTop = document.documentElement.scrollHeight;
                  }, 100)

                  return {
                    messages
                  }
                })
              }}
            >
              {counterpart.translate("chatScreen.btn.send")}
            </Button>
          </div>
        </Grid>
        <Navigator
          title={counterpart.translate("chatScreen.title")}
        />
      </>
    );
  }
}

// define classes and style your components
let useStyles = theme => ({
  userInputContainer: {
    justifyContent: "center",
    boxSizing: "border-box",
    position: "fixed",
    bottom: "56px",
    left: "0px",
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    padding: "10px"
  },
  userInput: {
    width: "100%",
    maxWidth: "500px"
  }
})

export default withStyles(useStyles)(Chat);