import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Typography, ButtonGroup, TextField, Button, TextareaAutosize } from '@material-ui/core';

import counterpart from 'counterpart';

/**
 * @author Jakob Froembgen
 * description: Container Feed Screen
 */
class FeedComponent extends Component {

  state = {
    headline: "",
    description: ""
  }

  render() {

    const { classes } = this.props

    return (
      <>
        <div className={classes.container}>
          <div>
            <Typography variant="h5" style={{ marginBottom: "6px" }}>
              {this.props.headline}
            </Typography>
            <Typography>
              {this.props.text}
            </Typography>
          </div>

          {this.props.edit ?
            <>
              <TextField
                id="headline"
                label={counterpart.translate("feedScreen.input")}
                variant="outlined"
                type="text"
                maxLength={1}
                value={this.state.headline}
                onChange={e => this.setState({ headline: e.target.value })}
                fullWidth
              />
              <TextareaAutosize
                maxLength={300}
                variant="outlined"
                className={classes.textarea}
                id="descriptionArea"
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
                placeholder={counterpart.translate("feedScreen.textArea")}
              />
              <ButtonGroup fullWidth size="small" color="primary" aria-label="small outlined primary button group">
                <Button
                  className={classes.btnAdd}
                  onClick={() => {
                    this.props.onAdd(this.state.headline, this.state.description);
                    this.setState({ headline: "", description: "" })
                  }}
                >
                  {counterpart.translate("feedScreen.btn.add")}
                </Button>
              </ButtonGroup>
            </>
            :
            <ButtonGroup
              fullWidth
              size="small"
              color="primary"
              aria-label="small outlined primary button group"
            >
              <Button
                className={classes.btnClose}
                onClick={this.props.onDelete}
              >
                X
              </Button>
              <Button
                className={classes.btnInfo}
                onClick={this.props.onInfo}
              >
                {counterpart.translate("feedScreen.btn.info")}
              </Button>
            </ButtonGroup>
          }
        </div>
      </>
    );
  }
}

let useStyles = theme => ({
  textarea: {
    borderRadius: "4px",
    padding: "10px",
    minHeight: "180px !important",
    minWidth: "100%",
    width: "100%",
    border: "1px solid rgba(118, 118, 118, 0.4)",
    fontFamily: "sans-serif",
    fontSize: "16px",
    margin: "10px 0",
    boxSizing: "border-box"
  },
  container: {
    justifyContent: "space-between",
    textAlign: "left",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "15px",
    marginBottom: "20px",
    minHeight: "200px",
    wordBreak: "break-all",
    display: "flex",
    flexDirection: "column",
    flexFlow: "wrap",
    alignContent: "space-between",

    WebkitBoxShadow: "0px 5px 10px -6px rgba(0,0,0,0.4)",
    MozBoxShadow: "0px 5px 10px -6px rgba(0,0,0,0.4)",
    boxShadow: "0px 5px 10px -6px rgba(0,0,0,0.4)"
  },
  btnClose: {
    color: "white",
    backgroundColor: "#AF6666",
    "&:hover": {
      backgroundColor: "#AF6666"
    }
  },
  btnInfo: {
    color: "white",
    backgroundColor: "#4A5A9D",
    "&:hover": {
      backgroundColor: "#4A5A9D"
    }
  },
  btnAdd: {
    color: "white",
    backgroundColor: "#7BB472",
    "&:hover": {
      backgroundColor: "#7BB472"
    }
  }
})

export default withStyles(useStyles)(FeedComponent);