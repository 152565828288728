import React, { Component } from 'react';
import './../App.css';
import { Link } from 'react-router-dom';
import { Grid, Button, TextField, Box } from '@material-ui/core';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import PostData from './../services/PostData';
import AuthData from './../services/AuthData';
import counterpart from 'counterpart';
import PasswordInput from '../Components/PasswordInput';

/**
 * @author Jakob Froembgen
 * description: login screen
 */
class Login extends Component {

	state = {
		email: "",
		password: "",
	}

	_login() {
		if ("activeElement" in document)
			document.activeElement.blur();
		//this.props.setLoading(true)
		let params = queryString.parse(this.props.location.search)
		PostData("login", { email: this.state.email, password: this.state.password })
			.then(data => {
				if ("error" in data) {
					//this.props.setLoading(false)
					//this.props.setError(data.error)
				} else if ("success" in data) {
					// AuthData.email = data.email
					// AuthData.name = data.name
					// AuthData.authenticated = true
					//this.props.setLoading(false)
					if ("next" in params)
						this.props.history.push(params.next)
					else
						this.props.history.push("/")
				}
			})
			.catch(errorMessage => {
				//this.props.setLoading(false)
				//this.props.setError(errorMessage)
			})
	}

	render() {

		const { classes } = this.props

		return (
			<form onSubmit={e => e.preventDefault()}>
				<div className={classes.loginContainer}>
					<Grid
						container
						direction="column"
						justify="center"
					>
						<Grid item xs>
							<TextField
								inputProps={{ 'aria-label': 'e-mail' }}
								margin="normal"
								fullWidth
								name="e-mail"
								type="e-mail"
								id="e-mail"
								autoComplete="current-e-mail"
								variant="outlined"
								label={counterpart.translate('loginScreen.email')}
								value={this.state.email}
								onChange={e => this.setState({ email: e.target.value })}
							/>
						</Grid>
						<Grid item xs>
							<PasswordInput
								margin="normal"
								fullWidth
								name="password"
								id="password"
								autoComplete="current-password"
								variant="outlined"
								value={this.state.password}
								onChange={e => this.setState({ password: e.target.value })}
							/>
						</Grid>
					</Grid>
					<Box my={4}></Box>
					<Grid
						container
						spacing={1}
						direction="column"
						justify="center"
					>
						<Grid item xs>
							<Button variant="contained" color="primary" fullWidth onClick={() => this._login()} type="submit">
								{counterpart.translate('loginScreen.btn.logIn')}
							</Button>
						</Grid>
						<Grid item xs>
							<Link to='/register'>
								<Button variant="outlined" color="secondary" fullWidth>
									{counterpart.translate('loginScreen.btn.register')}
								</Button>
							</Link>
						</Grid>
						<Grid item xs>
							<Link to="/forgot-password">
								<Button color="secondary" fullWidth>
									{counterpart.translate('loginScreen.forgotPassword')}
								</Button>
							</Link>
						</Grid>
					</Grid>
				</div>
			</form>
		);
	}
}

let useStyles = theme => ({
	loginContainer: {
		backgroundColor: "white",
		padding: "100px 20px",
		borderRadius: "10px"
	}
})

export default withStyles(useStyles)(Login);