import React, { Component } from 'react';
import './../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Navigator from "../Components/Navigator"

import counterpart from 'counterpart';
import FeedComponent from "../Components/FeedComponent";
import PostData from '../services/PostData';

/**
 * @author Jakob Froembgen
 * description: Feed Screen
 */
class Feed extends Component {

	state = {
		feeds: [],
		warningDialog: false,
		infoDialog: false,
		dialogData: [],
		loaded: false
	}

	componentDidMount() {
		this._updateFeeds();
		this.feedInterval = setInterval(this._updateFeeds.bind(this), 5000);
	}
	componentWillUnmount() {
		clearInterval(this.feedInterval)
	}

	_updateFeeds() {
		PostData("get_feeds")
			.then(data => {
				if ("success" in data) {
					this.setState({ feeds: data.feeds, loaded: true })
				}
			})
	}
	_createFeed(headline, description) {
		PostData("create_feed", { headline, description })
			.then(data => {
				if ("success" in data) {
					console.log(data)
					this._updateFeeds();
				}
			})
	}



	render() {

		// const { classes } = this.props
		if (!this.state.loaded)
			return null;
		return (
			<>
				<FeedComponent
					edit={true}
					onAdd={(headline, description) => {

						// check whether description or headline is empty string
						if (description.replace(" ", "") === "" || headline.replace(" ", "") === "")
							return

						this._createFeed(headline, description);
					}}
				/>
				<Grid container spacing={1} direction="column" justify="center" >
					{this.state.feeds.map((obj, key) => (
						<FeedComponent
							headline={obj.headline}
							text={obj.description}
							key={key}
							onInfo={() => {
								this.setState({
									infoDialog: !this.state.infoDialog,
									dialogData: [obj.requester, obj.created]
								})
							}}
							onDelete={() => {
								this.setState({
									warningDialog: !this.state.warningDialog,
									dialogData: [obj.requester, obj.created]
								})
							}}
						/>
					))}
					<Dialog
						open={this.state.infoDialog}
						keepMounted
					>
						<DialogTitle>{this.state.dialogData[0]}</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{counterpart.translate("feedScreen.date")} {this.state.dialogData[1]}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => { this.setState({ infoDialog: false }) }} color="primary">
								{counterpart.translate("feedScreen.btn.close")}
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={this.state.warningDialog}
						keepMounted
					>
						<DialogTitle>{this.state.dialogData[0]}</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{counterpart.translate("feedScreen.deleteText")}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button color="primary">
								{counterpart.translate("feedScreen.btn.delete")}
							</Button>
							<Button onClick={() => { this.setState({ warningDialog: false }) }} color="primary">
								{counterpart.translate("feedScreen.btn.cancel")}
							</Button>
						</DialogActions>
					</Dialog>
				</Grid>

				<Navigator
					title={counterpart.translate("feedScreen.title")}
				/>
			</>
		);
	}
}

// // define classes and style your components
let useStyles = theme => ({
	// 	// style
})

export default withStyles(useStyles)(Feed);