
import React, { Component } from 'react';
import './../App.css';
import { Link } from 'react-router-dom';
import {
  Grid,
  Radio,
  Button,
  Box,
  TextField,
  FormControl,
  TextFieldFormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PasswordInput from './../Components/PasswordInput'
import Warning from './../Components/Warning'
import counterpart from 'counterpart';
import PostData from './../services/PostData'

/**
 * @author Jakob Froembgen
 * description: screen description
 */
class Register extends Component {

  state = {
    email: "",
    name: "",
    password: "",
    repeat_password: "",
    redirectToLogin: false,
    showWarning: false
  }

  _register() {
    if ("activeElement" in document)
      document.activeElement.blur();
    this.setState({ showWarning: false })
    //this.props.setLoading(true)
    let { email, name, password, repeat_password } = this.state
    PostData("register", { email, name, password, repeat_password, language: counterpart.getLocale() })
      .then(data => {
        if ("error" in data) {
          //this.props.setLoading(false)
          //this.props.setError(data.error)
        } else if ("success" in data) {
          this.props.history.push("/login");
          //this.props.setLoading(false)
          // this.props.setError(counterpart.translate("success.registered"), () => {
          //   this.props.history.push("/login")
          // })
        }
      })
      .catch(errorMessage => {
        //this.props.setLoading(false)
        //this.props.setError(errorMessage)
      })
  }

  render() {

    const { classes } = this.props

    return (
      <>
        <form onSubmit={e => e.preventDefault()}>
          <div className={classes.loginContainer}>
            <Grid
              containers
              direction="column"
              justify="center"
            >
              <Grid item xs>
                <TextField
                  color="primary"
                  inputProps={{ 'aria-label': 'e-mail' }}
                  margin="normal"
                  fullWidth
                  name="e-mail"
                  type="e-mail"
                  id="e-mail"
                  variant="outlined"
                  label={counterpart.translate('registerScreen.eMail')}
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  inputProps={{ 'aria-label': 'name' }}
                  margin="normal"
                  fullWidth
                  name="name"
                  type="name"
                  id="name"
                  variant="outlined"
                  label={counterpart.translate('registerScreen.name')}
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </Grid>
              <Grid item xs>
                <PasswordInput
                  inputProps={{ 'aria-label': 'password' }}
                  margin="normal"
                  fullWidth
                  name="password"
                  id="password"
                  variant="outlined"
                  label={counterpart.translate('registerScreen.password')}
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </Grid>
              <Grid item xs>
                <PasswordInput
                  inputProps={{ 'aria-label': 'repeat password' }}
                  margin="normal"
                  fullWidth
                  name="repeat-password"
                  id="repeat-password"
                  variant="outlined"
                  label={counterpart.translate('registerScreen.repeatPassword')}
                  value={this.state.repeat_password}
                  onChange={e => this.setState({ repeat_password: e.target.value })}
                />
              </Grid>
              <Grid item xs>
                <FormControl component="fieldset" className={classes.formControl}>
                  <RadioGroup>
                    <FormControlLabel
                      value="tutor"
                      control={<Radio />}
                      label={counterpart.translate("registerScreen.tutor")}
                    />
                    <FormControlLabel
                      value="student"
                      control={<Radio />}
                      label={counterpart.translate("registerScreen.student")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs>
                <Box my={1}>
                  <Warning
                    openDialog={this.state.showWarning}
                    text={counterpart.translate('termsOfUse.text')}
                    confirmText={counterpart.translate('termsOfUse.btn.accept')}
                    onConfirm={() => this._register()}
                    onCancel={() => this.setState({ showWarning: false })}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => this.setState({ showWarning: true })}
                    >
                      {counterpart.translate('registerScreen.btn.createAccount')}
                    </Button>
                  </Warning>
                </Box>
                <Grid item xs>
                  <Link to="/login">
                    <Button variant="outlined" color="secondary" fullWidth>
                      {counterpart.translate('registerScreen.alreadyAccount')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </form>
      </>
    );
  }
}

let useStyles = theme => ({
  loginContainer: {
    backgroundColor: "white",
    padding: "100px 20px",
    borderRadius: "10px"
  }
})

export default withStyles(useStyles)(Register);