import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import counterpart from 'counterpart';

/**
 * A warning dialog with text and two buttons
 * prop text: Text to display
 */
class Warning extends Component {

  render() {
    const { classes } = this.props
    return (
      <div>
        {this.props.children}
        <Dialog
          open={this.props.openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={classes.dialog} id="alert-dialog-title">
            {counterpart.translate("warning.title")}
          </DialogTitle>
          <DialogContent className={classes.dialog}>
            <DialogContentText className={classes.dialog} id="alert-dialog-description">
              {this.props.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialog}>
            <Button onClick={() => this.props.onCancel()} color="primary" autoFocus>
              {counterpart.translate("warning.btn.cancel")}
            </Button>
            <Button onClick={() => this.props.onConfirm()} color="primary">
              {this.props.confirmText ? this.props.confirmText : counterpart.translate("warning.btn.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

let useStyles = theme => ({
  dialog: {
    background: "#2196f3",
    color: "white"
  },
  dialogText: {
    color: "white"
  },
  AppLogo: {
    height: "200px",
    pointerEvents: "none",
    padding: "25px 0",
    margin: "auto",
    display: "block"
  }
})

export default withStyles(useStyles)(Warning);